<template>
    <div class="text_shade_demo">
        <TextShade id="demo1" autoPlay="true">
            <template v-slot:shade>
                <h1>遮罩文字入场—从下往上</h1>
            </template>
        </TextShade>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>

        <TextShade id="demo2" autoPlay="true" direction="topToBottom">
            <template v-slot:shade>
                <h1>遮罩文字入场—从上往下</h1>
            </template>
        </TextShade>
    </div>
</template>
<script>
import TextShade from '@/components/animeTxt/TextShade.vue'
export default {
    components: {
        TextShade
    },
}
</script>
<style lang="less" scoped>
.text_shade_demo {
    padding: 48px;
    h1 {
        font-size: 36px;
        font-weight: bold;
    }
}
</style>
